import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import proquinalHistory from "services/browser-history";
import "./styles/project-card.scss";
import { STATIC_SPRADLING_DOMAIN } from "./constants/staticSpradlingDomain";

const ProjectCard = ({
  slug,
  title,
  image,
  city,
  products,
  islargeCard = false,
}) => {
  const [showProjectTitle, setShowProjectTitle] = useState(false);

  const handleChangeHoverState = () => {
    setShowProjectTitle(!showProjectTitle);
  };

  const handleGoToProjectDetails = () => {
    proquinalHistory.push(`/project/detail/${slug}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleReturnProducts = () => {
    return products?.edges?.map((product, index) => {
      const addComa = index !== products.edges.length - 1 ? ", " : "";

      return product.node.name + addComa;
    });
  };

  return (
    <div
      className={classNames({
        "col-12 col-lg-4 pt-5 pb-5 text-secondary": true,
        "col-lg-8": islargeCard,
      })}
    >
      <div
        className="container-project-card d-flex align-items-center justify-content-center project--card_container"
        style={{
          backgroundImage: `url(${STATIC_SPRADLING_DOMAIN}/${image})`,
        }}
        onClick={handleGoToProjectDetails}
        onMouseOver={handleChangeHoverState}
        onMouseOut={handleChangeHoverState}
      >
        <div className="d-flex align-items-center justify-content-center">
          {showProjectTitle && (
            <h1 className="text-white project--card_title">{title}</h1>
          )}
        </div>
      </div>
      <h5 className="pt-3">
        <FormattedMessage
          id="Projects.List.Card.location"
          defaultMessage="Location"
          values={{
            strong: (chunks) => <strong>{chunks}</strong>,
            location: `${city?.name}, ${city?.country?.name}`,
          }}
        />
      </h5>
      <h5>
        <FormattedMessage
          id="Projects.List.Card.product"
          defaultMessage="Product"
          values={{
            strong: (chunks) => <strong>{chunks}</strong>,
            product: handleReturnProducts(),
          }}
        />
      </h5>
    </div>
  );
};

export default ProjectCard;
