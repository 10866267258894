import React, { useEffect, useState } from "react";
import CategoryFilter from "./components/CategoryFilter";
import ProjectsList from "./components/ProjectsList";
import { useIntl } from "react-intl";
import "./styles/SearchProjectsByCategory.scss";
import { getCurrentFilterParam } from "./constants/getCurrentFilterParam";
import { FILTER_KEY_BY_PARAM } from "./constants/filterKeyByParam";

const SearchProjectsByCategory = ({ history, location }) => {
  const intl = useIntl();
  const [currentFilterParam, setCurrentFilterParam] = useState("");
  const [filterValues, setFilterValues] = useState({
    products: [],
    instances: [],
    cities: [],
    countries: [],
    authorizations: [],
    marketSegments: [],
    keyword: "",
    lang: intl.locale,
  });

  const formatFilterParams = () => {
    const searchParams = new URLSearchParams(location.search);
    let obj = {};

    

    searchParams.forEach((value, key) => {
      let parsedValue;

      try {
        parsedValue = JSON.parse(value);
        parsedValue = Array.isArray(parsedValue) ? parsedValue : value;
      } catch (e) {
        parsedValue = value; // Use the original value if a parsing error occurs
      }

      obj = {
        [FILTER_KEY_BY_PARAM[key]]:
          key !== FILTER_KEY_BY_PARAM.keyword 
            ? Array.isArray(parsedValue)
              ? [...parsedValue]
              : [parsedValue]
            : value
      };
    });

    return obj;
  };

  const handleCategoriesSelected = (e, instancesGroup) => {
    if (location.search) {
      history.push({
        pathname: location.pathname,
        search: "",
      });
    }

    const { name, value, checked } = e.target;

    // NOTE - Convert an array of group instances if it has values
    const instances = instancesGroup.length !== 0 && JSON.parse(value)

    setFilterValues((prevState) => ({
      ...prevState,
      [name]: checked
        ? prevState[name]
          ? instances 
            ? [...prevState[name], ...instances]
            : [...prevState[name], value]
          : [value]
        : instances 
          ? (prevState[name] || []).filter((item) => !instances.includes(item))
          : (prevState[name] || []).filter((item) => item !== value),
      keyword: "",
    }));
  };

  useEffect(() => {
    const formattedParams = formatFilterParams();
    const filterParam = getCurrentFilterParam(formattedParams, location, intl);

    setFilterValues((prevFilterValues) => ({
      ...prevFilterValues,
      ...formattedParams,
    }));
    setCurrentFilterParam(filterParam);
  }, [location.search]);

  return (
    <div>
      <div className="container">
        <div className="row d-flex justify-content-between">
          <div className="col-12 col-lg-4 search--projects--by__category-title ">
            <h2 className="font-weight-bold text-uppercase text-secondary">
              {currentFilterParam}
            </h2>
          </div>
          <div className="col-12 col-lg-8 p-0 pt-5 p-0">
            <CategoryFilter
              filters={filterValues}
              handleCategoriesSelected={handleCategoriesSelected}
            />
          </div>
        </div>
        <ProjectsList filters={filterValues} />
      </div>
    </div>
  );
};

export default SearchProjectsByCategory;
