import React from "react";
import { FormattedMessage } from "react-intl";
import {
  referencesQuery,
  businessUnitsQuery,
  citiesQuery,
  countriesQuery,
  segmentsQuery,
} from "../queries";

export const CATEGORIES_FILTER_ID = {
  products: "referencesAvailableForProjectSearch",
  instances: "businessUnitAvailableForProjectSearch",
  cities: "citiesAvailableForProjectSearch",
  countries: "countryAvailableForProjectSearch",
  authorizations: "authorizations",
  marketSegments: "marketSegments",
};

const OPTIONS_FOR_ATHORIZATION = {
  total: "AUTORIZACION TOTAL",
  partial: "SOLO PARA USO INTERNO",
  none: "SIN AUTORIZACIÓN",
};

export const CATEGORIES = [
  {
    id: CATEGORIES_FILTER_ID.products,
    name: "products",
    titleCategory: (
      <FormattedMessage
        id="Projects.Filter.product"
        key="product"
        defaultMessage="Product"
      />
    ),
    optionsQuery: referencesQuery,
  },
  {
    id: CATEGORIES_FILTER_ID.instances,
    name: "instances",
    titleCategory: (
      <FormattedMessage
        id="Projects.Filter.bussines"
        key="bussines"
        defaultMessage="Business Unit"
      />
    ),
    optionsQuery: businessUnitsQuery,
  },
  {
    id: CATEGORIES_FILTER_ID.cities,
    name: "cities",
    titleCategory: (
      <FormattedMessage
        id="Projects.Filter.city"
        key="city"
        defaultMessage="City"
      />
    ),
    optionsQuery: citiesQuery,
  },
  {
    id: CATEGORIES_FILTER_ID.marketSegments,
    name: "marketSegments",
    titleCategory: (
      <FormattedMessage
        id="Projects.Filter.segment"
        key="segment"
        defaultMessage="Segment"
      />
    ),
    optionsQuery: segmentsQuery,
  },
  {
    id: CATEGORIES_FILTER_ID.countries,
    name: "countries",
    titleCategory: (
      <FormattedMessage
        id="Projects.Filter.country"
        key="country"
        defaultMessage="Country"
      />
    ),
    optionsQuery: countriesQuery,
  },
  {
    id: CATEGORIES_FILTER_ID.authorizations,
    name: "authorizations",
    titleCategory: (
      <FormattedMessage
        id="Projects.Filter.authorization"
        key="authorizations"
        defaultMessage="Authorization"
      />
    ),
    options: [
      {
        id: OPTIONS_FOR_ATHORIZATION.total,
        name: OPTIONS_FOR_ATHORIZATION.total,
      },
      {
        id: OPTIONS_FOR_ATHORIZATION.partial,
        name: OPTIONS_FOR_ATHORIZATION.partial,
      },
      {
        id: OPTIONS_FOR_ATHORIZATION.none,
        name: OPTIONS_FOR_ATHORIZATION.none,
      },
    ],
  },
];
