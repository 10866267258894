import imageSliderProjectDetail1 from "../img/1.jpg";
import imageSliderProjectDetail2 from "../img/2.jpg";
import imageSliderProjectDetail3 from "../img/3.jpg";
import imageSliderProjectDetail4 from "../img/4.jpg";

export const DESIGN_SECTORS = [
  {
    id: 1,
    src: imageSliderProjectDetail1,
    alt: "Image 1",
    title: "Salud:",
    description:
      "Contamos con un amplio y variado portafolio de soluciones ideales para espacios hospitalarios y sanitarios.",
  },
  {
    id: 2,
    src: imageSliderProjectDetail2,
    alt: "Image 2",
    title: "Educación:",
    description:
      "Ofrecemos un amplio portafolio de telas, pisos, alfombras y gramas para todos los espacios que conforman un centro educativo.",
  },
  {
    id: 3,
    src: imageSliderProjectDetail3,
    alt: "Image 3",
    title: "Hoteleria y Entretenimiento:",
    description:
      "Nuestro portafolio de productos y soluciones complementan todas las áreas, desde las habitaciones donde se requiere confort y tranquilidad, hasta las áreas más exigentes expuestas al sol y al agua.",
  },
  {
    id: 4,
    src: imageSliderProjectDetail4,
    alt: "Image 4",
    title: "Corporativo:",
    description:
      "Ofrecer confort, tranquilidad y bienestar en los empleados es necesario para mejorar el clima y la productividad.",
  }
];
