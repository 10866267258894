import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import "./styles/CategoryAccordion.scss";
import { useFilterOptions } from "./hooks/useFilterOptions";

const CategoryAccordion = ({
  titleCategory,
  name,
  options,
  filters,
  handleCategoriesSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { filterOptions, handleMixInstances } = useFilterOptions(options, name);

  const handleClick = () => setIsOpen(!isOpen);

  const handleChangeFiltersCheckbox = (e, currentOption) => {
    const instancesGroup = handleMixInstances(currentOption);

    if (instancesGroup.length !== 0) {
      e.target.value = JSON.stringify(instancesGroup);
    }
    
    handleCategoriesSelected(e, instancesGroup);
  }

  return (
    <div id="accordion" className="m-2 m-lg-0">
      <div className="category-accordion-container">
        <p className="category-accordion-title m-0" onClick={handleClick}>
          {titleCategory}
          <span className="float-right">{!isOpen ? "+" : "-"}</span>
        </p>
        <Collapse isOpen={isOpen}>
          {filterOptions?.map((option) => (
            <div key={`item_${option.id}`}>
              <label className="cursor-pointer p-0 m-0">
                <input
                  name={name}
                  type={"checkbox"}
                  value={option.id}
                  checked={
                    filters[name] &&
                    filters[name].find(
                      (val) => val !== "authorizations" && val === option.id
                    )
                  }
                  className="mr-1"
                  onChange={(e) => handleChangeFiltersCheckbox(e, option)}
                />
                <p className="category-accordion-option">
                  {option.name.toLowerCase()}
                </p>
              </label>
            </div>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default CategoryAccordion;
