import gql from "graphql-tag";

export const projectsQuery = gql`
  query projects(
    $products: [ID]
    $cities: [ID]
    $instances: [ID]
    $countries: [ID]
    $authorizations: [ID]
    $marketSegments: [String]
    $keyword: String
    $lang: String
  ) {
    promotedProjects(
      products: $products
      cities: $cities
      instances: $instances
      countries: $countries
      authorizations: $authorizations
      marketSegments: $marketSegments
      keyword: $keyword
      lang: $lang
    ) {
      edges {
        node {
          id
          slug
          title
          image
          marketSegment
          products {
            edges {
              node {
                id
                name
              }
            }
          }
          city {
            id
            name
            region {
              id
              name
            }
            country {
              id
              name
            }
          }
          relatedProjects {
            edges {
              node {
                id
                slug
                image
                title
                city {
                  id
                  name
                  country {
                    id
                    name
                  }
                }
                products {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          authorization
        }
      }
    }
  }
`;
