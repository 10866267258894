import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import "./styles/ProjectDetailsHeaderInfo.scss";
import { AUTHORIZATION_PROJECT } from "./constants";
import classNames from "classnames";
import { Tooltip } from "reactstrap";

const ProjectDetailsHeaderInfo = ({ title, city, products, authorization, specifiedBy, marketSegment }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const navigateToThePatternDetail = (pattern) => {
    const productSlug = pattern.node.slug
    window.open(`/products/${productSlug}`, "_blank")
  }

  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <>
      <div className="mt-lg-4">
        <div className="col-lg-12 d-flex flex-column justify-content-center p-0 pl-md-4 pl-lg-5 ml-lg-4">
          <h1 className="font-weight-bold">{title}</h1>
          <div className="mt-4">
            <div className="col-12 col-lg-8 p-0 project-spec">
              <h6 className="font-weight-bold text-uppercase mb-1">
                <FormattedMessage
                  id="ProjectDetailsHeaderInfo.SpecifiedBy"
                  description="Specified By"
                  defaultMessage="Specified By"
                />
              </h6>
              <p className="project--details_description">
                {specifiedBy?.map((item, index) => {
                  const addComa = index !== products.edges.length - 1 ? ", " : "";
                  return item + addComa
                })}
              </p>
            </div>
            <div className="col-12 col-lg-4 p-0 project-spec">
              <h6 className="font-weight-bold text-uppercase mb-1">
                <FormattedMessage
                  id="ProjectCard.Location"
                  description="Location"
                  defaultMessage="Location"
                />
              </h6>
              <p className="project--details_description">
                {`${city?.name}, ${city?.country?.name}`}
              </p>
            </div>
            <div className="col-12 col-lg-8 p-0 project-spec">
              <h6 className="font-weight-bold text-uppercase mb-1">
                <FormattedMessage
                  id="ProjectDetailsHeaderInfo.Segment"
                  description="Segment"
                  defaultMessage="Segment"
                />
              </h6>
              <p className="project--details_description">
                {marketSegment}
              </p>
            </div>
            <div className="col-12 col-lg-4 p-0 mt-4">
              <h6 className="font-weight-bold text-uppercase mb-1">
                <FormattedMessage
                  id="ProjectCard.Product"
                  description="Product"
                  defaultMessage="Product"
                />
              </h6>
              {products?.edges?.map((product, index) => {
                const addComa = index !== products.edges.length - 1 ? ", " : "";

                return <p className="project--details_description project--details__product d-inline" 
                  onClick={() => navigateToThePatternDetail(product)}>
                  {product.node.name + addComa}
                  </p>
              })}
            </div>
          </div>
        </div>

        <div className="mt-5 mt-lg-5 d-flex align-items-end justify-content-start w-100 pl-md-4 px-lg-5 ml-lg-4">
          {(authorization === AUTHORIZATION_PROJECT.WITHOUT_AUTHORIZATION ||  
            authorization === AUTHORIZATION_PROJECT.ONLY_INTERN_USE)
            && (
            <span
              className="d-inline-block project--details_authorization-tooltip bg-danger"
              tabIndex="0"
              id="TooltipExample"
            >
              <i className="fa fa-info-circle mr-2 text-white float-right mt-2" />
              <h5
                className={classNames({
                  "w-75 px-3 px-sm-4 py-1 text-white m-0 project--details_authorization mt-2 mb-2": true,
                })}
              >
                <FormattedMessage
                  id="ProjectDetailsHeaderInfo.ContentNotPermittedToUse"
                  description="Content not permitted for use: Contact the designated marketing personnel"
                  defaultMessage="Content not permitted for use: Contact the designated marketing personnel"
                />
              </h5>
              <Tooltip
                isOpen={tooltipOpen}
                target="TooltipExample"
                toggle={toggle}
                className="project--details_authorization-tooltip-message"
              >
                <FormattedMessage
                  id="ProjectDetailsHeaderInfo.ContentNotPermittedToUseTooltip"
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    br: <br />,
                  }}
                />
              </Tooltip>
            </span>
          )}
          {authorization === AUTHORIZATION_PROJECT.TOTAL_AUTHORIZATION && (
            <h5
              className={classNames({
                "w-75 px-4 py-2 text-center text-white m-0 project--details_authorization": true,
                "bg-success": true,
              })}
            >
              <FormattedMessage
                id="ProjectDetailsHeaderInfo.ContentPermittedToUse"
                description="Content permitted for use"
                defaultMessage="Content permitted for use"
              />
            </h5>
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectDetailsHeaderInfo;
