/**
 * Retrieves the current filter parameter based on the formatted parameters, location, and intl object.
 * @param {Object} formattedParams - The formatted parameters object.
 * @param {Object} location - The location object.
 * @param {Object} intl - The intl object.
 * @returns {string} The current filter parameter.
 */
export const getCurrentFilterParam = (formattedParams, location, intl) => {
  let currentParam = ""

  Object.entries(formattedParams).forEach(([, value]) => {
    if (!location.state) return currentParam = value

    const { businessData } = JSON.parse(location.state)
    const { instanceLabel } = businessData
    currentParam = intl.formatMessage({ id: instanceLabel.props.id })
  })

  return currentParam
}